var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('btn-new',{attrs:{"to":"/customers/new"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.dialogFilters = true}}},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s('mdi-filter')}}),_vm._v(" Filtros ")],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.customers,"options":_vm.pagination,"footer-props":{
              'items-per-page-options': [10, 20, 50]
            }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadCustomers]},scopedSlots:_vm._u([{key:"item.cnpj",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cpfCnpj")(item.cnpj))+" ")]}},{key:"item.active",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Sim' : 'Não')+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('table-btn-edit',{attrs:{"href":("/customers/" + (item.id) + "/edit")}}),_c('table-btn-delete',{on:{"click":function($event){return _vm.clickDeleteItem(item)}}})]}}],null,true)})],1)],1)],1)],1),_c('dialog-delete',{attrs:{"show":_vm.dialogDelete,"loading":_vm.loadingDelete},on:{"confirm":_vm.deleteItem,"cancel":function($event){_vm.dialogDelete = false}}}),_c('dialog-filters',{on:{"filter":_vm.onFilter},model:{value:(_vm.dialogFilters),callback:function ($$v) {_vm.dialogFilters=$$v},expression:"dialogFilters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }