<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <btn-new to="/customers/new" />

          <v-btn
            color="info"
            class="ml-2"
            outlined
            @click="dialogFilters = true"
          >
            <v-icon
              v-text="'mdi-filter'"
              class="mr-2"
            />
            Filtros
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="customers"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50]
              }"
              @update:options="loadCustomers"
            >
              <template v-slot:[`item.cnpj`]="{ item }">
                {{ item.cnpj | cpfCnpj }}
              </template>
              <template v-slot:[`item.active`]="{ item }">
                {{ item.active ? 'Sim' : 'Não' }}
              </template>
              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <table-btn-edit :href="`/customers/${item.id}/edit`" />
                <table-btn-delete @click="clickDeleteItem(item)" />
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-delete
      :show="dialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
      @cancel="dialogDelete = false"
    />

    <dialog-filters
      v-model="dialogFilters"
      @filter="onFilter"
    />

  </div>
</template>

<script>
  import customersApi from '@/api/customers'
  import _ from 'lodash'

  export default {
    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogFilters: () => import('./CustomersListFilters'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        dialogDelete: false,
        dialogFilters: false,
        count: 0,
        deleteId: null,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Razão Social', value: 'companyName' },
          { text: 'CNPJ', value: 'cnpj' },
          { text: 'Ramo de Atividade', value: 'activityBranch.description' },
          { text: '', value: 'actions', align: 'right' },
        ],
        customers: [],
        pagination: {},
        filters: {},
      }
    },

    methods: {
      async loadCustomers () {
        try {
          this.loading = true

          const response = await customersApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
            ..._.pickBy(this.filters)
          })

          this.customers = response.data.customers
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async clickDeleteItem (customer) {
        this.deleteId = customer.id
        this.dialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await customersApi.delete(this.deleteId)

          this.loadCustomers()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.dialogDelete = false
        }
      },

      onFilter (filters) {
        this.filters = filters
        this.loadCustomers()
      },

    },

  }
</script>
